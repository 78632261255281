import React from "react";
import "./Navbar.css";
import { location, logo, offer, truck } from "../../assets/imagesPath";
import { Link } from "react-router-dom";
import PATHS from "../../routes/Paths";

const TopNavbar = () => {
  return (
    <div className="topNavbar d-flex align-items-center justify-content-between paddingTopnav topnavText">
      <p className="wlcmText textWlcm">
        Welcome to <span className="brandName">Easy QIST</span>
      </p>

      <div className="marquee">
        <div className="marquee-content">
          <p className="wlcmText"><b>Currently the service is available only in Lahore</b></p>
          <p className="wlcmText"><b>Currently the service is available only in Lahore</b></p>
          <p className="wlcmText"><b>Currently the service is available only in Lahore</b></p>
          <p className="wlcmText"><b>Currently the service is available only in Lahore</b></p>
          <p className="wlcmText"><b>Currently the service is available only in Lahore</b></p>
          <p className="wlcmText"><b>Currently the service is available only in Lahore</b></p>
          <p className="wlcmText"><b>Currently the service is available only in Lahore</b></p>
          <p className="wlcmText"><b>Currently the service is available only in Lahore</b></p>
        </div>
      </div>

      <Link
        to={PATHS.orderHistory}
        className="nav-link"
        data-toggle="tab"
      >
        <img src={truck} className="me-2" />
        Track order and history
      </Link>
    </div>
  );
};

export default TopNavbar;
