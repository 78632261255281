import React, { useEffect, useState } from "react";
import "../../components/formStyleSheet/FormStyling.css";
import { toast, ToastContainer } from "react-toastify";
import Button from "../button/Button";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import ModalComponent from "../modalSetup/Modal";
import { Modal } from "react-bootstrap";
import OtpInput from "react-otp-input";
import {
  useLoginMutation,
  useRegisterMutation,
  useSignUpMutation,
  useVerifyUserMutation,
} from "../../services/Api";
import { useDispatch } from "react-redux";
import { loggedIn } from "../../redux/AuthSlice";
import PhoneInput from "react-phone-input-2";
import SigninModal from "../allModals/SignupModal";

const LoginModal = (props) => {
  const [otp, setOtp] = useState("");
  const [phone, setPhone] = useState("");
  const [showLoginModal, setShowLoginModal] = useState(false);
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    email: Yup.string().required("Email is required").email("Email is invalid"),
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const signupModalHandler = () => {
    setShowLoginModal((prev) => !prev);
  };

  const allValues = watch();
  const number = watch();

  //login
  const [login, { isLoading: signUpLoading }] = useLoginMutation();
  const [otpCode, setOtpCode] = useState(null);

  const onLogin = (data) => {
    const { number } = data || {};
    if (!phone) {
      alert("Phone Number is required for login!");
      return;
    }
    console.log("data-->", data);
    let params = {
      phone_no: '+' + phone,
      // phone_no: `+92${number}`,
    };

    // console.log("data on increment cartData ");

    if (phone?.length == 12) {
      login({ data: params })
        .unwrap()
        .then((payload) => {
          console.log("payload-->", payload);
          const { code, status, message } = payload;

          // console.log("SignUp Daata", payload);
          if (status && code) {
            setOtpCode(code);
          } else {
            alert(message);
          }
        })
        .catch((error) => {
          console.log("error", error);
          // toast.error("")
          // const { code, status } = error.data;

          // // console.log("SignUp Daata", payload);
          // if (status && code) {
          //   setOtpCode(code);
          // }
        });
    } else {
      toast.error("Invalid phone number");
    }
  };

  //

  //Verify OTP
  const [verifyUser, { isLoading: verifyLoading }] = useVerifyUserMutation();

  const onVerifyogin = () => {
    if (!otp) {
      alert("OTP is required!");
      return;
    }
    let params = {
      register: 0,
      sms_code: otp,
      phone_no: `+${phone}`,
    };

    verifyUser({ data: params })
      .unwrap()
      .then((payload) => {
        console.log("Loginned", payload);
        if (payload.status) {
          dispatch(loggedIn(payload));
          props.handleCloseSignupModal();
        } else {
          alert(payload.message);
        }
      })
      .catch((error) => {
        console.log("error", error);

        // const { code, status } = error.data;

        // // console.log("SignUp Daata", payload);
        // if (status && code) {
        //   setOtpCode(code);
        // }
      });
  };

  const handlePhoneChange = (value) => {
    if (!value.startsWith('92')) {
      value = '92' + value.replace(/^92*/, '');
    }
    setPhone(value);
  };

  return (
    <ModalComponent
      size="md"
      show={true}
      handleClose={props.handleCloseSignupModal}
    >
      <Modal.Body className="overflow m-2 lgModals customScroll pt-0 mt-0">
        {!otpCode ? (
          <>
            <h1 className="mainHeading mb-4 text-center">Login</h1>
            <p className="subHeading">Log in with your Phone Number.</p>

            <div className="row">
              <div className="col-12 mt-4">
                <label className="formLabel">Enter Phone No.</label>
                <PhoneInput
                  country={'pk'}
                  value={phone}
                  onChange={handlePhoneChange}
                  onlyCountries={['pk']}
                  disableDropdown={true}
                  placeholder='+92 xxx xxxxxxx'
                />

                {/* <div className="row">
                  <div className="col-md-3">
                    <select className="inputField formSelect">
                      <option value="+92">+92</option>
                    </select>
                  </div>

                  <div className="col-md-9">
                    <input
                      type="tel"
                      placeholder="XXX XXXX XXX"
                      className="inputField"
                      {...register("number")}
                    />
                  </div>
                </div> */}
              </div>

              <div className="col-12 mt-4">
                <Button
                  label="Login"
                  className="greenBtn w-100"
                  onClick={handleSubmit(onLogin)}
                  loading={verifyLoading || signUpLoading}
                />
              </div>

              <h3 className="text-end mt-4 mb-4">Don't have an account? <span className="text-primary cursor" onClick={signupModalHandler}>Signup</span></h3>
            </div>
          </>
        ) : (
          <div className="col-12 otpBox mt-4">
            {/* <label className="formLabel">Enter OTP: {otpCode}</label> */}
            <label className="formLabel">Enter OTP: An OTP code has sent to <b>+{phone}</b></label>
            <OtpInput
              value={otp}
              inputType="number"
              onChange={setOtp}
              numInputs={4}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
            />
            <div className="col-12 mt-4">
              <Button
                label="Verify"
                className="greenBtn w-100"
                onClick={onVerifyogin}
              />
            </div>
          </div>
        )}
      </Modal.Body>

      {showLoginModal && (
        <SigninModal handleCloseSignupModal={signupModalHandler} />
      )}
    </ModalComponent>
  );
};

export default LoginModal;
