import React, { useState } from "react";
import "../../components/formStyleSheet/FormStyling.css";
import Button from "../button/Button";
import { useForm } from "react-hook-form";
import ModalComponent from "../modalSetup/Modal";
import { Modal } from "react-bootstrap";
import OtpInput from "react-otp-input";
import {
  useSignUpMutation,
  useVerifyUserMutation,
} from "../../services/Api";
import { useDispatch } from "react-redux";
import { loggedIn } from "../../redux/AuthSlice";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";

const SignupModal = (props) => {

  const [otp, setOtp] = useState("");
  const [phone, setPhone] = useState("");
  const [cnic, setCnic] = useState("");
  const [error, setError] = useState('');
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [signUp, { isLoading: signUpLoading }] = useSignUpMutation();
  const [otpCode, setOtpCode] = useState(null);

  const onSignUp = (data) => {
    const { first_name, last_name } = data || {};
    if (!first_name) {
      alert("First name is requird!");
      return;
    }
    if (!last_name) {
      alert("Last name is required!");
      return;
    }
    if (!phone) {
      alert("Phone number is required !");
      return;
    }

    let params = {
      name: first_name + "" + last_name,
      phone_no: '+' + phone,
      cnic_number: cnic,
    };

    if (phone?.length == 12) {
      signUp({ data: params })
        .unwrap()
        .then((payload) => {
          const { code, status, message } = payload;
          if (status && code) {
            setOtpCode(code);
          } else {
            alert(message);
          }
        })
        .catch((error) => {
        });
    } else {
      toast.error("Invalid phone number");
    }
  };

  const [verifyUser, { isLoading: verifyLoading }] = useVerifyUserMutation();

  const onVerifyogin = () => {
    if (!otp) {
      alert("OTP is required!");
      return;
    }
    let params = {
      register: 1,
      sms_code: otp,
      phone_no: '+' + phone,
    };

    verifyUser({ data: params })
      .unwrap()
      .then((payload) => {
        if (payload.status) {
          dispatch(loggedIn(payload));
          props.handleCloseSignupModal();
        } else {
          alert(payload.message);
        }
      })
      .catch((error) => {
        const { code, status } = error.data;
        if (status && code) {
          setOtpCode(code);
        }
      });
  };

  const handlePhoneChange = (value) => {
    if (!value.startsWith('92')) {
      value = '92' + value.replace(/^92*/, '');
    }
    setPhone(value);
  };

  const handleChange = (e) => {
    const value = e.target.value;

    if (!/^\d*$/.test(value)) {
      setError('Please enter only numbers.');
      return;
    }

    setCnic(value);

    if (value.length < 13) {
      setError('Number must be exactly 13 digits.');
    } else if (value.length > 13) {
      setError('Number must be exactly 13 digits.');
    } else {
      setError('');
    }
  };

  return (
    <ModalComponent
      size="md"
      show={true}
      handleClose={props.handleCloseSignupModal}
    >
      <Modal.Body className="overflow m-2 lgModals customScroll pt-0 mt-0">
        {!otpCode ? (
          <>
            <h1 className="mainHeading mb-4 text-center">Signup</h1>
            <p className="subHeading">Sign up with your Phone Number.</p>

            <div className="row">
              <div className="col-md-6">
                <label className="formLabel">First Name</label>
                <input
                  type="text"
                  placeholder="Type.."
                  className="inputField"
                  {...register("first_name")}
                />
              </div>

              <div className="col-md-6">
                <label className="formLabel">Last Name</label>
                <input
                  type="text"
                  placeholder="Type.."
                  className="inputField"
                  {...register("last_name")}
                />
              </div>

              <div className="col-12 mt-4">
                <label className="formLabel">Enter Phone No.</label>
                <PhoneInput
                  country={'pk'}
                  value={phone}
                  onChange={handlePhoneChange}
                  onlyCountries={['pk']}
                  disableDropdown={true}
                  placeholder='+92 xxx xxxxxxx'
                />
              </div>

              <div className="col-12 mt-4">
                <label className="formLabel">CNIC</label>
                <input
                  type="text"
                  placeholder="Type.."
                  className="inputField"
                  value={cnic}
                  onChange={handleChange}
                  maxLength={13}
                />
                {error && <p style={{ color: 'red' }}>{error}</p>}
              </div>

              <div className="col-12 mt-4">
                <Button
                  label="REQUEST an OTP"
                  className="greenBtn w-100"
                  onClick={handleSubmit(onSignUp)}
                  loading={signUpLoading}
                />
              </div>
            </div>
          </>
        ) : (
          <div className="col-12 otpBox mt-4">
            <OtpInput
              value={otp}
              inputType="number"
              onChange={setOtp}
              numInputs={4}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
            />
            <div className="col-12 mt-4">
              <Button
                label="Verify OTP"
                className="greenBtn w-100"
                onClick={handleSubmit(onVerifyogin)}
                loading={verifyLoading}
              />
            </div>
          </div>
        )}
      </Modal.Body>
    </ModalComponent>
  );
};

export default SignupModal;