import React, { useEffect, useRef, useState } from "react";
import "./Navbar.css";
import {
  blackHeart,
  cart,
  delIcon,
  email,
  laptopCart,
  logo,
  profile,
  search,
  whatsappImg,
} from "../../assets/imagesPath";
import { Link, useNavigate } from "react-router-dom";
import SigninModal from "../allModals/SignupModal";
// import Button from '../button/Button';
import PATHS from "../../routes/Paths";
import { useDispatch, useSelector } from "react-redux";
import LoginModal from "../allModals/loginModal";
import { userLogout } from "../../redux/AuthSlice";
import {
  useGetAllProductQuery,
  useViewCartOrdersQuery,
} from "../../services/Api";
// import { setSearch } from "../../redux/ProductSlice";
import Button from "../button/Button";
import { setSearch } from "../../redux/ProductSlice";
import { useDebounce } from "use-debounce";

const MidNavbar = ({ test }) => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showLoginModal2, setShowLoginModal2] = useState(false);
  const [name, setName] = useState("");
  const [debouncedName] = useDebounce(name, 300);
  const [searchResults, setSearchResults] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const searchRef = useRef(null);
  const navigator = useNavigate();
  const dispatch = useDispatch();
  const cartData = useSelector((data) => data?.products?.productDetail);
  const auth = useSelector((data) => data.auth);
  const [searchTxt, setSearchTxt] = useState("");
  const { isLoggedIn, userId: ID } = auth || {};
  const { data: viewCartOrders } = useViewCartOrdersQuery({
    params: { user_id: ID, order_status: 0 },
  });

  // const [showCartDropdown, setShowCartDropdown] = useState(false);
  const cartRef = useRef(null);

  const signupModalHandler = () => {
    setShowLoginModal((prev) => !prev);
  };

  const loginModalHandler = () => {
    setShowLoginModal2((prev) => !prev);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      dispatch(setSearch(searchTxt));
    }
  };

  useEffect(() => {
    if (!searchTxt) {
      dispatch(setSearch(""));
    }
  }, [searchTxt]);

  const {
    data: getAllProduct,
    refetch: getAllProductRefetch,
    loading: getAllProductLoading,
  } = useGetAllProductQuery({ params: { start: 0, limit: 100000, name: debouncedName } });

  useEffect(() => {
    const fetchData = async () => {
      if (debouncedName) {
        const { data } = await getAllProductRefetch();
        const filteredProducts = data?.data?.filter(product => product != null);
        setSearchResults(filteredProducts || []);
        setShowResults(true);
      } else {
        setSearchResults([]);
        setShowResults(false);
      }
    };
    fetchData();
  }, [debouncedName]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowResults(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <nav className="d-block d-md-flex navbar navbar-expand-lg justify-content-between paddingTopnav">
        <div className="mb-3 mb-md-0 text-center">
          <img
            src={logo}
            className="img-fluid brand-logo cursor"
            onClick={() => navigator(PATHS.home)}
          />
        </div>

        <div className="d-flex align-items-center justify-content-between mb-4 d-md-none">
          <div className="d-flex align-items-center">
            <img src={whatsappImg} className="me-1 callImg" />
            <p className="footertextColor">
              +923000828456
            </p>
          </div>

          <div className="d-flex align-items-center">
            <img
              src={email}
              className="me-2 callImg green"
              style={{ width: 15, tintColor: "green" }}
            />
            <p className="footertextColor">
              easyqist@gmail.com
            </p>
          </div>
        </div>

        <div className="position-relative w-100 w-md-50 ms-md-5 ms-0 mb-3 mb-md-0 me-md-4 d-flex align-items-center gap-5">

          <div className="position-relative w-100" ref={searchRef}>
            <input
              type="text"
              className="mainSearchbar"
              placeholder="Type name"
              onChange={(e) => setName(e.target.value)}
              onFocus={() => searchResults.length > 0 && setShowResults(true)}
            />
            <img src={search} className="searchIcon" alt="Search" />

            {showResults && searchResults?.length > 0 && (
              <ul className="resultsList">
                {searchResults?.map((product) => (
                  <li
                    key={product?.id}
                    onClick={() => { navigator("/productDetails", { state: { id: product?.id } }); setShowResults(false); setName("") }
                    }
                  >{product?.name}</li>
                ))}
              </ul>
            )}
          </div>

          {/* <input
            type="text"
            className="mainSearchbar"
            placeholder="Search products..."
            onFocus={() => navigator(PATHS.shop)}
            onChange={(e) => setSearchTxt(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <img src={search} className="searchIcon" /> */}

          <div
            className="nav-item mt-1"
            onClick={() => navigator(PATHS.cart)}
          >
            <div className="position-relative" ref={cartRef}>
              <div className="cursor">
                <img src={cart} alt="" />
                {viewCartOrders?.data.order_products?.length > 0 ? (
                  <span className="cartItemsCount">
                    {viewCartOrders?.data?.order_products?.length}
                  </span>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        {/* <div>
          <Button
            style={{ height: 43 }}
            label="Search"
            className="greenBtn w-100"
            onClick={() => dispatch(setSearch(searchTxt))}
          />
        </div> */}

        <div className="align-items-center justify-content-end ms-3">
          <div className="d-lg-flex d-grid justify-content-center align-items-center gap-4 gap-lg-5 mt-5 mt-lg-0">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 gap-4 gap-lg-5 w-100 text-center midnavText">
              {!isLoggedIn && (
                <>
                  <li className="nav-item">
                    <Link
                      style={{ fontWeight: "bold" }}
                      className="nav-link"
                      data-toggle="tab"
                      onClick={loginModalHandler}
                    >
                      Login
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      style={{ fontWeight: "bold" }}
                      onClick={signupModalHandler}
                      className="nav-link"
                      data-toggle="tab"
                    >
                      Signup
                    </Link>
                  </li>
                </>
              )}

              {/* <li
                className="nav-item mt-1"
                onClick={() => navigator(PATHS.cart)}
              >
                <div className="position-relative" ref={cartRef}>
                  <div className="cursor">
                    <img src={cart} alt="" />
                    {viewCartOrders?.data.order_products?.length > 0 ? (
                      <span className="cartItemsCount">
                        {viewCartOrders?.data?.order_products?.length}
                      </span>
                    ) : null}
                  </div>
                </div>
              </li> */}

              {isLoggedIn && (
                <li className="hideOnMob">
                  <div className="dropdown profileDropdown mx-auto">
                    <a
                      className="d-flex align-items-center decorNone flex-column flex-lg-row gap-1  text-capitalize"
                      href="#"
                      role="button"
                      id="dropdownMenuLink"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={profile}
                        height={25}
                        width={25}
                        className="me-2"
                      />
                    </a>

                    <ul
                      className="dropdown-menu userDropdownMenu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <Link to={PATHS.profile} className="decorNone">
                        <li>
                          <p className="dropdown-item">Profile</p>
                        </li>
                      </Link>
                      <hr className="m-1" />
                      <li
                        className="cursor"
                        onClick={() => dispatch(userLogout())}
                      >
                        <p className="dropdown-item">Logout</p>
                      </li>
                    </ul>
                  </div>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>

      {showLoginModal && (
        <SigninModal handleCloseSignupModal={signupModalHandler} />
      )}
      {showLoginModal2 && (
        <LoginModal handleCloseSignupModal={loginModalHandler} />
      )}
    </>
  );
};

export default MidNavbar;
