import React from "react";
import "./Navbar.css";
import { headphone, logo } from "../../assets/imagesPath";
import Button from "../button/Button";
import { Link, NavLink, useNavigate } from "react-router-dom";
import PATHS from "../../routes/Paths";
// import { useLogoutMutation } from '../../services/Api';
import { useDispatch, useSelector } from "react-redux";
import { userLogout } from "../../redux/AuthSlice";
import { toast } from "react-toastify";
import TopNavbar from "./TopNavbar";
import MidNavbar from "./MidNavbar";
// import { BASE_URL_IMAGES } from '../../services/ApiEndpoints';

const Navbar = () => {
  // const [logout, { isLoading }] = useLogoutMutation();
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const auth = useSelector((data) => data.auth);
  const { isLoggedIn } = auth || {};

  // const logoutFunc = () => {

  //   const data = {
  //     token: auth?.fcmtoken,
  //   }

  //   logout(data)
  //     .unwrap()
  //     .then(() => {
  //       dispatch(userLogout(null));
  //       navigator(PATHS.signin);
  //       toast.success('Logout Successfully');
  //     })
  //     .catch((error) => {
  //       dispatch(userLogout(null));
  //       navigator(PATHS.signin);
  //     })
  //     .finally(() => {
  //       dispatch(userLogout(null));
  //       navigator(PATHS.signin);
  //     });
  // };

  return (
    <>
      {isLoggedIn && <TopNavbar />}

      <MidNavbar />

      <nav className="navbar navbar-expand-lg navbar-light paddingTopnav justify-content-between">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo01"
          aria-controls="navbarTogglerDemo01"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
          <div className="d-lg-flex d-grid justify-content-center align-items-center gap-4 gap-lg-5 mt-5 mt-lg-0 w-100">
            <ul
              className="navbar-nav me-auto mb-2 mb-lg-0  w-100 text-center justify-content-center"
              style={{ gap: "5rem" }}
            >
              <li className="nav-item">
                <NavLink to={PATHS.home} className="nav-link" data-toggle="tab">
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to={PATHS.shop} className="nav-link" data-toggle="tab">
                  Shop
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={PATHS.brands}
                  className="nav-link"
                  data-toggle="tab"
                >
                  Brands
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={PATHS.about}
                  className="nav-link"
                  data-toggle="tab"
                >
                  About
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={PATHS.contactUs}
                  className="nav-link"
                  data-toggle="tab"
                >
                  Contact
                </NavLink>
              </li>

              <li className="nav-item showOnMob">
                <NavLink
                  to={PATHS.profile}
                  className="nav-link"
                  data-toggle="tab"
                >
                  Profile
                </NavLink>
              </li>
              <li
                className="nav-item showOnMob"
                onClick={() => dispatch(userLogout())}
              >
                <Link
                  className="nav-link"
                  data-toggle="tab"
                >
                  Logout
                </Link>
              </li>
            </ul>

            <div className="navbarInfoDiv">
              <img src={headphone} className="me-4" />
              <p className="navbarText">
                Contact Us 24/7 <br />{" "}
                <span className="navbarTextNum">+923000828456</span>
              </p>
            </div>

            {/* {auth.isLoggedIn && (
              <div className='dropdown profileDropdown mx-auto'>
                <a
                  className='d-flex align-items-center decorNone flex-column flex-lg-row gap-1  text-capitalize'
                  href='#'
                  role='button'
                  id='dropdownMenuLink'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  <img
                    src={BASE_URL_IMAGES + userDetail?.images?.url || dummyProfileImg}
                    onError={(e) => { e.target.onerror = null; e.target.src = dummyProfileImg; }}
                    height={40}
                    width={40}
                    className='me-2 rounded-circle'
                    alt=''
                  />
                  {userDetail?.first_name} {userDetail?.last_name}

                </a>

                <ul
                  className='dropdown-menu'
                  aria-labelledby='dropdownMenuLink'
                >
                  <Link
                    to={userDetail?.role_as == 'supermechanic' ? PATHS.shopprofile : PATHS.profile}
                    className='decorNone'
                  >
                    <li>
                      <p className='dropdown-item'>Profile</p>
                    </li>
                  </Link>
                  <hr className='m-1' />
                  <Link
                    to={PATHS.history}
                    className='decorNone'
                  >
                    <li>
                      <p className='dropdown-item'>History</p>
                    </li>
                  </Link>
                  <hr className='m-1' />
                  <li
                    className='cursor'
                    onClick={() => logoutFunc()}
                  >
                    <p
                      className='dropdown-item'
                      href='#'
                    >
                      Logout
                    </p>
                  </li>
                </ul>
              </div>
            )} */}
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
