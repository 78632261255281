export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const BASE_URL_IMAGES = process.env.REACT_APP_BASE_URL_IMAGES;
export const ID = 12;

export const API_END_POINTS = {
  /////////////////////////////<===MUTATIONS===>//////////////////////////////
  register: BASE_URL + "register",
  searchProduct: BASE_URL + "product/search",
  addToCart: BASE_URL + "add_to_cart",
  proceed: BASE_URL + "proceed",
  signUp: BASE_URL + "registeropt",
  login: BASE_URL + "loginopt",
  verifyUser: BASE_URL + "sendopt",
  updateAddress: BASE_URL + "update_address",
  contactUs: BASE_URL + "contactus",
  deleteOrder: BASE_URL + "order/delete",
  requestProduct: BASE_URL + "product/request_product",
  deleteUser: BASE_URL + "delete_user",
  /////////////////////////////<===QUERIES===>////////////////////////////////
  getBrands: BASE_URL + "get-brand",
  getProductCat: BASE_URL + "get-category",
  getAllProduct: BASE_URL + "getallproductcategory2",
  getAllSpecialProd: BASE_URL + "getallspecialproduct2",
  getAllTrendingProd: BASE_URL + "getalltrendingproduct2",
  getBanner: BASE_URL + "get-banner",
  getProductDetails: BASE_URL + "getProductDetail2",
  viewCartOrders: BASE_URL + "view_order_card",
  deleteCartOrder: BASE_URL + "del_order_product",
  getOrderDetails: BASE_URL + "view_order_detail",
  getBannerProducts: BASE_URL + "getbannerproducts",
  getOrderHistory: BASE_URL + "view_order_history",
  getOrderPayment: BASE_URL + "view_order_payment",
  changeOrderStatus: BASE_URL + "change_order_status",
  getRequestedProductDetail: BASE_URL + "request_products/user_side",
};