import React from "react";
import "./Footer.css";
import {
  appstore,
  card,
  delivery,
  email,
  facebook,
  insta,
  logo,
  mail,
  maps,
  payment,
  phone,
  playstore,
  process,
  tiktok,
  whatsappImg,
  youtube,
} from "../../assets/imagesPath";
import { Link, useNavigate } from "react-router-dom";
import PATHS from "../../routes/Paths";

const Footer = () => {
  const navigator = useNavigate();

  const openGoogleMaps = () => {
    window.open('https://maps.app.goo.gl/BDRaTRQXVsqYWkQv7', '_blank');
  };

  return (
    <>
      {/* <div className="cartFooter">
        <div className="row marginContainer mb-4 p-5">
          <div className="col-md-4 d-flex align-items-center gap-3">
            <img src={process} alt="" width={60} height={60} />
            <h3 className="cartFooterHeading">Easy Verification Process</h3>
          </div>

          <div className="col-md-4 d-flex align-items-center gap-3">
            <img src={card} alt="" width={60} height={60} />
            <h3 className="cartFooterHeading">No Bank Account / Card Required</h3>
          </div>

          <div className="col-md-4 d-flex align-items-center gap-3">
            <img src={delivery} alt="" width={60} height={60} />
            <h3 className="cartFooterHeading">Free Delivery</h3>
          </div>

          <div className="col-md-4 d-flex align-items-center gap-3">
            <img src={payment} alt="" width={60} height={60} />
            <h3 className="cartFooterHeading">No Documentation Charges</h3>
          </div>
        </div>
      </div> */}

      <div className="footer">
        <div className="row">
          <div className="col-md-4">
            <img
              src={logo}
              className="cursor brand-logo"
              onClick={() => navigator(PATHS.home)}
            />
            <div className="mt-5">
              <p className="contactUstext">Contact Us</p>

              <div className="d-flex align-items-start">
                <img src={whatsappImg} className="me-3 callImg" />
                <p className="footertextColor">
                  Whats App <br />
                  +923000828456
                </p>
              </div>

              <div className="d-flex align-items-start mt-4">
                <img src={phone} className="me-3 callImg" />
                <p className="footertextColor">
                  Call Us <br />
                  +923000828456
                </p>
              </div>
              <div className="d-flex align-items-start mt-4">
                <img
                  src={email}
                  className="me-3 callImg green"
                  style={{ height: 25, width: 20, tintColor: "green" }}
                />
                <p className="footertextColor">
                  Email <br />
                  easyqist@gmail.com
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <p className="footerHeading">
              <span>
                Customer <hr />
              </span>{" "}
              Services
            </p>

            <ul className="footerLinks">
              <li>
                <Link to={PATHS.termsAndConditions}>Terms & Conditions</Link>
              </li>
              <li>
                <Link to={PATHS.refundPolicy}>Return and Refund Policy</Link>
              </li>
              <li>
                <Link to={PATHS.privacyPolicy}>Privacy Policy</Link>
              </li>
            </ul>

            <img src={maps} alt="" height={180} className="w-100 rounded cursor mt-4" onClick={openGoogleMaps} />
          </div>

          <div className="col-md-4">
            <p className="footerHeading">Download App:</p>

            <div className="d-flex flex-column align-items-start gap-4 mt-4">
              <a href="https://play.google.com/store/apps/details?id=com.easy.qist" target="_blank">
                <img src={playstore} className="w-50" />
              </a>
              {/* <img src={appstore} className="w-50" /> */}
              <div className="gap-4 d-flex align-items-center mt-4">
                <a href="https://tiktok.com/@easyqist.com" target="_blank">
                  <img src={tiktok} height={37} />
                </a>
                <a href="https://www.facebook.com/profile.php?id=61565595124134&mibextid=ZbWKwL" target="_blank">
                  <img src={facebook} height={30} />
                </a>
                <a href="https://www.instagram.com/easyqist?igsh=MXhpeno3MHFuc3Vocw==" target="_blank">
                  <img src={insta} height={30} />
                </a>
                <a href="https://youtube.com/@easyqist?si=mG20u07I9ojyKFtK" target="_blank">
                  <img src={youtube} height={30} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
